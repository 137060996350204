var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "coupons-create-page" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitCoupon.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "b-card",
          { staticClass: "mb-1", attrs: { header: "Informações básicas" } },
          [
            _c("b-card-text", [
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "col-12 col-md-4" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Título interno "),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.title,
                          expression: "item.title",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.item.title.$error },
                      attrs: { name: "title-internal" },
                      domProps: { value: _vm.item.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.item, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-4 col-12" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Código "),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.coupon,
                          expression: "item.coupon",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.item.coupon.$error },
                      attrs: { type: "text", name: "coupon" },
                      domProps: { value: _vm.item.coupon },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.item, "coupon", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Desconto "),
                      ]),
                      _vm.item.type_discount.code == "money"
                        ? _c(
                            "b-input-group",
                            {
                              staticClass: "input-group-merge",
                              attrs: { prepend: "R$" },
                            },
                            [_c("b-form-input")],
                            1
                          )
                        : _vm._e(),
                      _vm.item.type_discount.code == "percentage"
                        ? _c(
                            "b-input-group",
                            {
                              staticClass: "input-group-merge",
                              attrs: { append: "%" },
                            },
                            [
                              _c("b-form-input", {
                                class: {
                                  "is-invalid":
                                    _vm.$v.item.value_discount.$error,
                                },
                                attrs: {
                                  type: "number",
                                  min: "1",
                                  max: "99",
                                  name: "discount",
                                },
                                model: {
                                  value: _vm.item.value_discount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "value_discount", $$v)
                                  },
                                  expression: "item.value_discount",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-2 col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Utilização "),
                      _c("i", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.top",
                            value:
                              "Quantidade de vezes que o cupom poderá ser utilizado.",
                            expression:
                              "\n                    'Quantidade de vezes que o cupom poderá ser utilizado.'\n                  ",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                        staticClass: "bi bi-question-circle",
                      }),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.limit,
                          expression: "item.limit",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.$v.item.limit.$error },
                      attrs: { name: "quantity", type: "number" },
                      domProps: { value: _vm.item.limit },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.item, "limit", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "col-md-3 col-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Data de início "),
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CalendarIcon" },
                              }),
                            ],
                            1
                          ),
                          _c("flat-pickr", {
                            staticClass: "form-control pl-1",
                            class: { "is-invalid": _vm.$v.item.start.$error },
                            attrs: {
                              config: {
                                dateFormat: "d/m/Y",
                                altFormat: "Y-m-d",
                                locale: "pt",
                              },
                            },
                            model: {
                              value: _vm.item.start,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "start", $$v)
                              },
                              expression: "item.start",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-md-3 col-6" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _c("i", {
                          staticClass: "text-danger bi bi-record-circle",
                        }),
                        _vm._v(" Data de término "),
                      ]),
                      _c(
                        "b-input-group",
                        { staticClass: "input-group-merge" },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CalendarIcon" },
                              }),
                            ],
                            1
                          ),
                          _c("flat-pickr", {
                            staticClass: "form-control pl-1",
                            class: { "is-invalid": _vm.$v.item.end.$error },
                            attrs: {
                              config: {
                                dateFormat: "d/m/Y",
                                altFormat: "Y-m-d",
                                locale: "pt",
                              },
                            },
                            model: {
                              value: _vm.item.end,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "end", $$v)
                              },
                              expression: "item.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("b-card-footer", { staticClass: "p-0 pt-1" }, [
              _c("div", { staticClass: "flex" }, [
                _c("span", [_vm._v("Status do cupom de desconto")]),
                _c(
                  "span",
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "custom-control-success float-left",
                        attrs: {
                          checked: "true",
                          name: "check-button",
                          switch: "",
                        },
                        model: {
                          value: _vm.item.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.item, "status", $$v)
                          },
                          expression: "item.status",
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "switch-icon-left" },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "PowerIcon" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticClass: "switch-icon-right" },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "PowerIcon" },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.status ? "Ativo" : "Inativo") +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
        _c(
          "b-card",
          {
            staticClass: "invoice-preview-card",
            attrs: { header: "Produto(s)" },
          },
          [
            _c("b-card-text", [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c("b-form-radio-group", {
                      staticClass: "radio-rules mb-0",
                      attrs: {
                        stacked: "",
                        options: _vm.optionsApply,
                        "value-field": "code",
                        "text-field": "title",
                      },
                      model: {
                        value: _vm.item.type_to_apply,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "type_to_apply", $$v)
                        },
                        expression: "item.type_to_apply",
                      },
                    }),
                  ],
                  1
                ),
                _vm.item.type_to_apply === 1
                  ? _c("div", { staticClass: "col-12 pt-2" }, [
                      _vm._v(
                        " A regra será aplicada para todos os itens no carrinho. "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.type_to_apply === 2
                  ? _c(
                      "div",
                      { staticClass: "col-12 pt-2" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Digite o título do produto" } },
                          [
                            _c(
                              "v-select",
                              {
                                ref: "selectProduct",
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsProducts,
                                },
                                on: {
                                  search: _vm.fetchProducts,
                                  input: _vm.productSelected,
                                },
                                model: {
                                  value: _vm.searchProduct,
                                  callback: function ($$v) {
                                    _vm.searchProduct = $$v
                                  },
                                  expression: "searchProduct",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.selectProduct.open = false
                                      },
                                    },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.item.products.length
                          ? _c("h5", { staticClass: "mt-2" }, [
                              _vm._v(" Produtos selecionados "),
                            ])
                          : _vm._e(),
                        _c(
                          "b-list-group",
                          { attrs: { flush: "" } },
                          _vm._l(_vm.item.products, function (product, index) {
                            return _c("b-list-group-item", { key: index }, [
                              _c("div", { staticClass: "form-row" }, [
                                _c("div", { staticClass: "col-12 text-left" }, [
                                  _vm._v(" " + _vm._s(product.title) + " "),
                                ]),
                              ]),
                            ])
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.item.type_to_apply === 3
                  ? _c(
                      "div",
                      { staticClass: "col-12 pt-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group mb-0" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Digite o título da categoria"),
                            ]),
                            _c(
                              "v-select",
                              {
                                ref: "selectCategory",
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsCategories,
                                },
                                on: {
                                  search: _vm.fetchCategories,
                                  input: _vm.categorySelected,
                                },
                                model: {
                                  value: _vm.searchCategory,
                                  callback: function ($$v) {
                                    _vm.searchCategory = $$v
                                  },
                                  expression: "searchCategory",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.selectCategory.open = false
                                      },
                                    },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.item.categories.length
                          ? _c("h5", { staticClass: "mt-2" }, [
                              _vm._v(" Categorias selecionados "),
                            ])
                          : _vm._e(),
                        _c(
                          "b-list-group",
                          { attrs: { flush: "" } },
                          _vm._l(
                            _vm.item.categories,
                            function (product, index) {
                              return _c("b-list-group-item", { key: index }, [
                                _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 text-left" },
                                    [_vm._v(" " + _vm._s(product.title) + " ")]
                                  ),
                                ]),
                              ])
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "row justify-content-right" }, [
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("ButtonsActionsFooter", {
                attrs: {
                  routerBack: "coupon-list",
                  variant: "success",
                  submited: _vm.submited,
                  text: "Cadastrar",
                  subtext: "Aguarde...",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }